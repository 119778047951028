@media screen {
    .contentAdmin {
        min-height: 100vh;
        width: 100vw;
        background-color: $bgBack;
        overflow: hidden;
        .limitadorContent {
            margin: 0 auto;
            max-width: 1920px;
            &.monitor {
                max-width: 100%;
            }
            .content {
                display: flex;
                height: calc(100vh - 50px);
                overflow: hidden;
                padding: $spacing;
                .boxLeft {
                    width: 250px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    .box {
                        border-radius: $radius;
                        box-shadow: $shadow;
                        background: $bgComponent;
                        padding: $spacing;
                        overflow: auto;
                        height: 100%;
                        &.grow {
                            flex: 1;
                        }
                        &.footer {
                            height: 60px !important;
                            border-top: 1px solid $secondary-line !important;
                            border-radius: 0 0 $radius $radius;
                            overflow: hidden;
                        }
                        &.image {
                            padding: 0;
                            overflow: hidden;
                            border-radius: $radius $radius 0 0;
                            height: 144px !important;
                        }
                        &.top {
                            padding: $padding;
                            overflow: hidden;
                            border-radius: $radius $radius 0 0;
                            height: 50px !important;
                            color: #fff;
                            text-align: center;
                            font-size: 20px;
                        }
                        &.with-image {
                            border-radius: 0 0 $radius $radius;
                        }
                        &.with-image-footer {
                            border-radius: $radius $radius 0 0;
                        }
                        &.with-footer {
                            border-radius: $radius $radius 0 0;
                        }
                    }
                }
                .boxCenter {
                    flex-grow: 1;
                    background: $bgComponent;
                    box-shadow: $shadow;
                    border-radius: $radius;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    position: relative;
                    &.center {
                        align-items: center;
                        overflow: auto;
                    }
                    .boxContent {
                        padding: $spacing;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        overflow-y: auto;
                        overflow-x: hidden;
                        &.clean {
                            padding: 0;
                        }
                        &.no-scroll {
                            overflow-y: hidden;
                            padding: 0;
                        }
                    }
                }
                .boxRight {
                    flex-grow: 1;
                    background: $bgComponent;
                    margin-left: $spacing;
                    box-shadow: $shadow;
                    border-radius: $radius;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    position: relative;
                    &.center {
                        margin-left: $spacing;
                        margin-right: $spacing;
                    }
                    &.right {
                        background: none;
                        flex-grow: 0;
                        width: 250px !important;
                        display: flex;
                        flex-direction: column;
                    }
                    .boxContent {
                        flex-grow: 1;
                        overflow-y: auto;
                        padding: $padding;
                        height: calc(100% - 60px);
                        &.no-padding {
                            padding: 0;
                        }
                        &.t20 {
                            padding-top: 20px;
                        }
                        &.with-footer {
                            height: calc(100% - 50px);
                        }
                    }
                    .footer {
                        height: 60px !important;
                        border-top: 1px solid $secondary-line !important;
                        padding: $padding;
                        text-align: right;
                        overflow: hidden;
                        flex-shrink: 0;
                    }
                    &.clean {
                        display: block;
                        padding: 0px;
                        background: transparent;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .contentAdmin {
        .limitadorContent {
            .content {
                .boxLeft {
                    .box {
                        &.grow {}
                        &.footer {}
                        &.image {}
                        &.with-image {}
                    }
                }
                .boxCenter {
                    .boxContent {
                        &.clean {}
                        &.no-scroll {}
                    }
                }
                .boxRight {
                    &.clean {}
                    .boxContent {
                        &.clean {}
                        &.no-scroll {}
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contentAdmin {
        .limitadorContent {
            .content {
                .boxLeft {
                    display: none;
                    .box {
                        &.grow {}
                        &.footer {}
                        &.image {}
                        &.with-image {}
                    }
                }
                .boxCenter {
                    .boxContent {
                        &.clean {}
                        &.no-scroll {}
                    }
                }
                .boxRight {
                    margin-left: 0;
                    border-radius: 0;
                    &.clean {}
                    .boxContent {
                        &.clean {}
                        &.no-scroll {}
                    }
                }
            }
        }
    }
}
