
.grid {
    display: flex;
    flex-direction: column;

    height: 100%;
    .container {
        flex: 1;
        overflow-y: auto;
        padding: $spacing;
        &.no-padding {
            padding: 0;
        }
    }
    .footer {
        height: 57px !important;
        border-top: 1px solid $secondary-line !important;
        padding: $spacing;
        text-align: right;
        overflow: hidden;
    }
}

.grid-users {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.monitor {
    height: 100%;
    .container {
        display: flex;
        height: calc(100vh - 50px);
        overflow: hidden;
        padding: $spacing;
        .box {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            border-radius: $radius;
            box-shadow: $shadow;
            background: $bgComponent;
            padding: $spacing;
            margin: 0 5px;
            overflow: auto;
            height: 100%;
            &.map {
                flex-grow: 1;
            }
            &.list {
                display: flex;
                flex-direction: column;
                width: 225px;
                padding: 0;
                .top {
                    padding: $spacing;
                    overflow: hidden;
                    height: 40px !important;
                    color: #fff;
                    text-align: center;
                    font-size: 17px;
                    flex-shrink: 0;
                }
                .grow {
                    flex-shrink: 1;
                    height: 100%;
                }
                .footer {
                    display: flex !important;
                    height: 45px !important;
                    flex-shrink: 0;
                    justify-content: center !important;
                    overflow: hidden;
                    padding: 5px 0;
                }
            }
            &.messages {
                width: 300px;
            }
            &.logs {
                width: 400px;
                .boxContent {
                    padding: $spacing;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    &.clean {
                        padding: 0;
                    }
                    &.no-scroll {
                        overflow-y: hidden;
                        padding: 0;
                    }
                }
            }

        }
    }
}

.resultEmpty {
    display: flex;
    height: 100%;
    justify-items: center;
    justify-content: center;
    color: $white;
    min-height: 100px !important;
    &.slim {
        height: auto !important;
    }
    .empty {
        margin: auto;
        span {
            color: $white !important;
            font-size: 16px;
        }
    }
}
