*,
html,
body {
    margin: 0;
    padding: 0;
    font-family: "Comfortaa", cursive, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:root {
    scrollbar-color: rgb(210, 210, 210) transparent !important;
    scrollbar-width: thin !important;
}

*::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
}

*::-webkit-scrollbar-track {
    background-color: #444444;
}

*::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 16px;
    width: 5px !important;
}

// @font-face{
//     font-family:AntDesign;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/AntDesign.ttf) format('truetype')
// }
    
// @font-face{
//     font-family:Entypo;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Entypo.ttf) format('truetype')
// }

// @font-face{
//     font-family:EvilIcons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/EvilIcons.ttf) format('truetype')
// }

// @font-face{
//     font-family:Feather;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Feather.ttf) format('truetype')
// }

// @font-face{
//     font-family:FontAwesome;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/FontAwesome.ttf) format('truetype')
// }

// @font-face{
//     font-family:FontAwesome5;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/FontAwesome5.ttf) format('truetype')
// }

// @font-face{
//     font-family:FontAwesome5Brands;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/FontAwesome5_Brands.ttf) format('truetype')
// }

// @font-face{
//     font-family:Foundation;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Foundation.ttf) format('truetype')
// }

// @font-face{
//     font-family:Ionicons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Ionicons.ttf) format('truetype')
// }

// @font-face{
//     font-family:MaterialCommunityIcons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/MaterialCommunityIcons.ttf) format('truetype')
// }

// @font-face{
//     font-family:MaterialIcons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/MaterialIcons.ttf) format('truetype')
// }

// @font-face{
//     font-family:Octicons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Octicons.ttf) format('truetype')
// }

// @font-face{
//     font-family:SimpleLineIcons;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/SimpleLineIcons.ttf) format('truetype')
// }

// @font-face{
//     font-family:Zocial;
//     font-style:normal;
//     font-weight:400;
//     src:url(fonts/Zocial.ttf) format('truetype')
// }