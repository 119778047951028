
.map-root {
    height: 100% !important;
}

.leaflet-container {
   height: 100% !important;
   width: 100%;
   margin: 0 auto;
}

.leaflet-routing-container {
    display: none;
}