
// Components
$bgBack: #181A1E;
$bgComponent: #24252B;
$bgComponentHover: #2f3138;
$bgInput: #43444a;
$bgLine: #4C4E58;
$bgLikes: #5a5a5a;

// Colors
$primary: #D9000D;
$primary-hover: #8e0009;
$secondary: #43444a;
$secondary-hover: #2f3138;
$secondary-line: #2f3138;

$white: #ffffff;
$grey: #ccc;
$shadow: 0px 3px 6px #0000004d;

// Spacing
$margin: 10px;
$padding: 10px;
$spacing: 10px;
$radius: 5px;

// Fonts
$fontSize:16px;
$fsTitle:16px;
$fsSubtitle:16px;
$fsText:16px;
$fsTextSmall:16px;
