
.header {
    background: $bgComponent;
    box-shadow: $shadow;
    height: 50px;
    width: 100vw;
    .limitadorHeader {
        height: 50px;
        margin: 0 auto;
        max-width: 1920px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        padding: 0 20px;
        &.monitor {
            max-width: 100%;
        }
        .logo {
            display: flex;
            align-items: center;
        }
        .search {
            background: $bgInput;
            border: none;
            max-width: 500px;
            border-radius: 5px;
            flex-shrink: 5;
            .ant-input {
                color: $white !important;
                background: transparent;
            }
            .ant-input-suffix {
                span {
                    color: $white;
                }
            }
        }
        .icons {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .ant-badge {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                span {
                    font-size: 24px;
                    color: $white;
                }
            }

            .ant-avatar {
                box-shadow: 0px 10px 30px #00000080 !important;
                border: 1px solid #ffffff11;
                margin-left: 15px;
            }
            .ant-btn {
                background: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                span {
                    font-size: 24px;
                    color: $white;
                }
            }
            .callSearch {
                display: none;
            }
        }
    }
}
