@media screen {
    .menu-admin {
        .item {
            color: $grey;
            padding: 7px;
            cursor: pointer;
            border-radius: 2px;
            a, a:visited {
                color: $white;
            }
            &:hover {
                color: $white;
                background: $bgComponentHover;
            }
            &.active {
                color: $white;
                background: $bgInput;
            }
            .anticon {
                margin-right: 10px !important;
            }
        }
    }

    .sub-menu-admin{
        background-color: $bgComponent !important;
        box-shadow: $shadow !important;
        border-bottom:1px solid $bgInput !important;
    }
    
    // ANTD
    .ant-menu-item-selected, .ant-menu-submenu-active {
        background-color: $bgInput !important;
        color: #fff !important;
    }
    .ant-menu-item {
        &:hover {
            background-color: $bgComponentHover !important;
        }
    }
    .ant-menu-sub{
        background-color: $bgInput !important;
        box-shadow: $shadow !important;
        .ant-menu-item-active{
            background-color: $bgComponent !important;
        }
        .ant-menu-item-selected{
            background-color: $bgInput !important;
        }
    }
    
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    
}

@media screen and (max-width: 768px) {
    
}

