
.title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: $white;
    margin-top: 4px;
    margin-bottom: $margin;
    width: 100% !important;
    border-bottom: 1px solid $bgInput;
}

.description {
    display: block;
    font-size: 13px;
    font-weight: 700;
    color: $white;
}

