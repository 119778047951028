
.card {
    border-radius: $radius;
    box-shadow: $shadow;
    background: $bgComponent;
    padding: $spacing;
    margin-bottom: $margin;
    .type {
        color: $primary;
        border-bottom: 1px solid $secondary;
        text-align: center;
        padding-bottom: 5px;
    }
    .title {
        border-bottom: 0;
    }
    .description {
        font-size: 12px;
    }

    &.log {
        color: $grey;
        b {
            color: $primary;
        }
        .type {
            font-size: 25px;
            font-weight: 700;
            color: $primary;
            margin-bottom: 0;
            width: 100% !important;
            text-transform: uppercase;
            text-align: left;
            border-bottom: 0px solid $bgInput;
        }
        .date {
            font-size: 15px;
            font-weight: 700;
            color: $grey;
            margin-bottom: $margin;
            width: 100% !important;
            border-bottom: 1px solid $bgInput;
            text-transform: uppercase;
            text-align: left;
        }
        .bug {
            display: block;
            font-size: 15px;
            color: $white;
            border-top: 1px solid $bgInput;
            border-bottom: 1px solid $bgInput;
            padding: $padding 0;
        }
    }

    &.user-online {
        width: 120px;
        height: 200px;
        padding: 0;
        margin: 10px;

        .img-user-online {
            width: 120px;
            height: 120px;
            background-size: cover;
            background-position: center;
        }

        .name {
            color: white;
            font-size: 13px;
            text-align: center;
            margin: 5px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .app {
            color: #ccc;
            font-size: 11px;
            text-align: center;
            margin: 5px;
        }

        .date {
            color: #ccc;
            font-size: 8px;
            text-align: center;
            margin: 5px;
        }
    }

    &.user-online-map {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        margin: 0;
        padding: 0;
        .img-user-online {
            width: 60px;
            height: 60px;
            border-radius: 60px;
            background-size: cover;
            background-position: center;
        }
    }
}

.img-user-map {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-size: cover;
    background-position: center;
}
