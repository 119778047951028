@media screen {
    .contentAuth {
        min-height: 100vh;
        width: 100vw;
        background-image: url("../images/bglogin.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
            background: transparent;
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: $bgBack;
            padding: 0 20px;
            align-self: flex-end;
            box-shadow: none;
            & .logo {
                display: none;
            }
            & .search {
                display: none;
            }
            & .icons {
                width: 20%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .ant-badge {
                    display: none;
                }

                .ant-avatar {
                    display: none;
                }
                .ant-btn {
                    background: transparent;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                    span {
                        font-size: 24px;
                        color: $bgBack;
                    }
                }
                .callSearch {
                    display: block;
                    span {
                        font-size: 24px;
                        color: $bgBack;
                    }
                }
            }
        }
        .boxCenter {
            display: flex;
            min-height: calc(100vh - 50px);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .conteinerForm {
                align-self: center;
                background: $bgComponent;
                width: 390px;
                padding: 20px;
                border-radius: 5px;
                .logo {
                    text-align: center;
                    padding-bottom: 20px;
                    margin-bottom: 10px;
                    border-bottom: 2px solid $bgInput;
                    img {
                        height: 45px;
                    }
                }
                .ant-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .row {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        .ant-form-item {
                            &:nth-child(2) {
                                width: calc(100% - 85px);
                            }
                            &:nth-child(1) {
                                min-width: 70px;
                                margin-right: 15px;
                            }
                        }
                    }
                    .ant-form-item {
                        margin: 0;
                        margin-bottom: 5px;
                    }
                    .ant-form-item-label {
                        padding: 0;
                    }
                    .ant-form-item-required {
                        &::before {
                            display: none;
                        }
                        color: $white;
                        margin: 0;
                        padding: 0;
                    }
                    .ant-form-item-control-input-content {
                        .ant-input {
                            background: #43444a;
                            height: 44px;
                            font-size: 16px;
                            color: #fff;
                            border-color: #43444a;
                            border-radius: 5px;
                        }
                        .ant-select {
                            .ant-select-selector {
                                background: #43444a;
                                height: 44px;
                                font-size: 16px;
                                color: #fff;
                                border-color: #43444a;
                                border-radius: 5px;
                                .ant-select-selection-item {
                                    height: 44px;
                                    line-height: 44px;
                                }
                            }
                            .ant-select-arrow {
                                color: #fff !important;
                            }
                        }
                    }
                    .btnPrimary {
                        margin-top: 10px;
                    }
                    .boxOther {
                        align-items: right;
                        padding: $padding;
                        a {
                            color: #fff;
                            font-size: 16px;
                        }
                    }
                    .boxAcessar .ant-form-item-control-input-content {
                        display: flex;
                        justify-content: flex-start;
                        a {
                            margin-top: 10px;
                            text-align: center;
                            color: #fff;
                            font-size: 16px;
                            span {
                                color: #d8010e;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            .box-rede {
                display: flex;
                width: 100% !important;
                justify-content: center;

                .ant-btn {
                    background: $primary;
                    color: $white;
                    border: none;
                    margin: 10px 7.5px;
                    .anticon {
                        svg {
                            margin-bottom: -1px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
        flex: 0 1 auto !important;
    }
    .contentAuth {
        .boxCenter {
            .conteinerForm {
                max-width: 90vw;
                padding: 20px;
                .logo {
                    text-align: center;
                    padding-bottom: 20px;
                    margin-bottom: 15px;
                    border-bottom: 2px solid $bgInput;
                    img {
                        height: 45px;
                    }
                }
            }
        }
    }
}
