.ant-form-item {
    margin: 0 0 8px;
}
.ant-checkbox {
    padding: 0 !important;
    label {
        font-size: 16px;
        color: $white;
        font-weight: 700;
    }
}
.ant-col {
    padding: 0 0 2px !important;
}
.ant-form-item-label {
    label {
        color: $white;
    }
}
.ant-form-item-label > label.ant-form-item-required::before {
    display: none;
}
.ant-input {
    background: $secondary !important;
    border-color: $secondary !important;
    border-radius: $radius;
    color: #fff;
    min-height: 35px;
    font-size: 16px;
}
.ant-checkbox-wrapper {
    span {
        color: $white !important;
    }
}
.ant-select {
    .ant-select-selector {
        background: $secondary !important;
        height: 35px;
        font-size: 16px;
        color: #fff;
        border-color: $secondary !important;
        border-radius: $radius !important;
        .ant-select-selection-item {
            color: #fff;
            border-color: $bgComponentHover !important;
            background: $secondary !important;
            height: 28px;
            line-height: 28px;
            font-size: 15px;
            .anticon-close {
                margin: 3px;
                color: $primary !important;
            }
        }
    }
    .ant-select-arrow {
        color: $primary !important;
    }
}

.ant-input-affix-wrapper, .ant-input-password {
    background: $secondary !important;
    border: none !important;
    height: 44px !important;
    border-radius: $radius;
    padding: 0 10px !important;
    span {
        color: #fff !important;
    }
}
