.btnPrimary {
    background: $primary !important;
    border-radius: $radius !important;
    border: none !important;
    height: 41px !important;
    color: $white !important;
    min-width: 150px !important;
    font-size: 16px !important;
    &:hover {
        background: $primary-hover !important;
    }
    &.icon {
        min-width: 5px !important;
    }
    &.in-line {
        height: 37px !important;
    }
    &.disabled {
        background: $secondary !important;
        color: $grey !important;
    }
}
.btnSecondary {
    background: $secondary !important;
    border-radius: $radius !important;
    border: none !important;
    height: 41px !important;
    color: $white !important;
    min-width: 150px !important;
    font-size: 16px !important;
    &:hover {
        background: $secondary-hover !important;
    }
    &.icon {
        min-width: 5px !important;
    }
    &.ml {
        margin-left: 10px !important;
    }
    &.mr {
        margin-right: 10px !important;
    }
    &.in-line {
        height: 37px !important;
    }
    &.no-margin {
        margin: 0 !important;
    }
}

.btnPrimarySmall {
    background: $primary !important;
    border-radius: $radius !important;
    border: none !important;
    height: 35px !important;
    color: $white !important;
    min-width: 50px !important;
    font-size: 15px !important;
    border-bottom: 1px solid $primary-hover !important;
    &:hover {
        border-bottom: 1px solid $primary-hover  !important;
    }
}
.btnSecondarySmall {
    background: $secondary !important;
    border-radius: $radius !important;
    border: none !important;
    height: 35px !important;
    color: $white !important;
    min-width: 50px !important;
    font-size: 15px !important;
    border-bottom: 1px solid $secondary-line !important;
    &:hover {
        background: $secondary-hover !important;
    }
}

.mr {
    margin-right: 5px;
}
